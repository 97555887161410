import {
	Link as ChakraLink,
	ChakraProps,
	useStyleConfig,
} from "@chakra-ui/react";
import { InlineLinkButtonFragment } from "src/__generated__/cms-schema.codegen";
import { NextLink } from "src/components/NextLink";
import { createBreadcrumbs } from "src/lib/utils";
import { CMSButtonLabel } from "./CMSButtonLabel";

export type CMSLinkButtonStyle = "outline" | "solid" | "text";

export type CMSLinkButtonProps = InlineLinkButtonFragment & {
	inHeader?: boolean;
	children?: React.ReactNode;
} & ChakraProps;

export const useGetInternalHref = (
	internal: InlineLinkButtonFragment["internal"],
) => {
	switch (internal?.__typename) {
		case "BreadcrumbRecord": {
			return `/${createBreadcrumbs(internal)
				.map(({ slug }) => slug)
				.join("/")}`;
		}

		case "PageRecord": {
			return `/${internal.slug === "index" ? "" : internal.slug}`;
		}

		default:
			return "#";
	}
};

export const CMSLinkButton: React.FC<CMSLinkButtonProps> = ({
	label,
	linkType,
	linkStyle,
	jumpMark,
	internal,
	url,
	leftIcon,
	rightIcon,
	analyticsId,
	inHeader,
	children,
	title,
	// destructure the following two props to get rid of it
	__typename,
	text,
	...rest
}) => {
	const sx = useStyleConfig("CMSButton", {
		variant: linkStyle,
		inHeader,
	});

	const internalHref = useGetInternalHref(internal);

	const common = {
		variant: linkStyle,
		sx,
		"data-testid": "cms-link-button",
		...(url?.endsWith(".pdf") ? { download: true } : {}),
		...rest,
	};

	switch (linkType) {
		// Link rendered
		case "external":
			return (
				<ChakraLink
					{...common}
					href={url ?? "#"}
					id={analyticsId}
					isExternal
					title={title ?? undefined}
				>
					{children ? (
						children
					) : (
						<CMSButtonLabel
							leftIcon={leftIcon?.name}
							rightIcon={rightIcon?.name}
							label={label}
							isExternal
						/>
					)}
				</ChakraLink>
			);
		case "internal": {
			if (!internal) {
				return null;
			}

			return (
				<NextLink href={internalHref} passHref>
					<ChakraLink
						{...common}
						id={analyticsId}
						title={title ?? undefined}
					>
						{children ? (
							children
						) : (
							<CMSButtonLabel
								leftIcon={leftIcon?.name}
								rightIcon={rightIcon?.name}
								label={label}
							/>
						)}
					</ChakraLink>
				</NextLink>
			);
		}
		default:
			return jumpMark ? (
				<ChakraLink
					{...common}
					href={jumpMark}
					id={analyticsId}
					title={title ?? undefined}
				>
					{children ? (
						children
					) : (
						<CMSButtonLabel
							leftIcon={leftIcon?.name}
							rightIcon={rightIcon?.name}
							label={label}
						/>
					)}
				</ChakraLink>
			) : null;
	}
};

// 🔬 RTL
