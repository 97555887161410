import {
	Accordion,
	AccordionButton,
	AccordionButtonProps,
	AccordionItem,
	AccordionPanel,
	AccordionPanelProps,
	AccordionProps,
	chakra,
} from "@chakra-ui/react";
import React, { createContext, ReactNode, useContext } from "react";
import { ChevronUp } from "../../icons";

export const HeaderAccordionButton: React.FC<
	{
		children: ReactNode;
	} & AccordionButtonProps
> = ({ children, ...rest }) => {
	const ctx = useHeaderAccordionContext();

	return (
		<AccordionButton {...rest}>
			<chakra.span>{children}</chakra.span>
			<ChevronUp
				transition="transform 150ms ease-in"
				transform={ctx.isExpanded ? "rotate(0turn)" : "rotate(0.5turn)"}
			/>
		</AccordionButton>
	);
};

export const HeaderAccordionContent: React.FC<
	{ children: ReactNode } & AccordionPanelProps
> = ({ children, ...rest }) => {
	return <AccordionPanel {...rest}>{children}</AccordionPanel>;
};

const HeaderAccordionContext = createContext<
	{ isExpanded: boolean; isDisabled: boolean } | undefined
>(undefined);

const useHeaderAccordionContext = () => {
	const ctx = useContext(HeaderAccordionContext);

	if (!ctx) {
		throw new Error(
			"Header Accordion Context was used outside of <HeaderAccordion>",
		);
	}

	return ctx;
};

export const HeaderAccordion: React.FC<AccordionProps> = ({
	variant = "header",
	children,
	...rest
}) => {
	return (
		<Accordion variant={variant} allowToggle {...rest}>
			<AccordionItem>
				{(state) => {
					return (
						<HeaderAccordionContext.Provider value={state}>
							{children}
						</HeaderAccordionContext.Provider>
					);
				}}
			</AccordionItem>
		</Accordion>
	);
};

// 🔬 TBD: Please evaluate
