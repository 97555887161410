import { chakra, Container, useMultiStyleConfig } from "@chakra-ui/react";
import React, { ReactNode } from "react";

type DesktopMenuProps = {
	logo: ReactNode;
	children: ReactNode;
};

export const DesktopMenu: React.FC<DesktopMenuProps> = ({ logo, children }) => {
	const styles = useMultiStyleConfig("DesktopMenu");

	return (
		<chakra.div __css={styles.wrapper}>
			<Container maxW="container.xl" sx={styles.inner}>
				{logo}
				<chakra.div __css={styles.children}>{children}</chakra.div>
			</Container>
		</chakra.div>
	);
};

// 🔬 TBD: Please evaluate
