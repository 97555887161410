import { Environment } from "@emilgroup/auth-sdk";

export const EMIL_PHONE_REGEX = /^\+49\d{8,14}$/;

export const environments = {
	prod: "https://api.eis.funk-experts.de",
	staging: "https://api.eis.funk-experts.de",
	test: Environment.Test,
} as const;

export enum Genders {
	Male = "male",
	Female = "female",
	Unspecified = "unspecified",
}

// 🔬 skip
