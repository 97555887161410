import {
	chakra,
	ChakraProps,
	ImageProps as ChakraImagePropsOriginal,
} from "@chakra-ui/react";
import NextImage from "next/image";
import React, { ComponentPropsWithoutRef } from "react";
import { Image as DatoImage, ResponsiveImageType } from "react-datocms";
import { AnyImageQuery } from "src/__generated__/cms-schema.codegen";
import { fillParent } from "src/styles/mixins";

export type DatoImageProps = Omit<
	ComponentPropsWithoutRef<typeof DatoImage>,
	"data"
>;

type DatoAssetInput = AnyImageQuery["type"][number];

export type DatoAssetData = Omit<
	DatoAssetInput,
	"responsiveImage" | "__typename"
> & {
	svgData?: string;
	responsiveImage?: ResponsiveImageType | null;
};

type ChakraImageProps = Omit<
	ChakraImagePropsOriginal,
	"onLoad" | "placeholder"
>;

type DatoAssetProps = ChakraImageProps & {
	data: DatoAssetData;
	svgProps?: ChakraProps;
	datoImageProps?: DatoImageProps;
	usePlaceholder?: boolean;
	priority?: boolean;
};

const ChakraDatoImage = chakra(DatoImage, {
	shouldForwardProp: (prop) =>
		[
			"style",
			"data",
			"layout",
			"usePlaceholder",
			"objectFit",
			"objectPosition",
			"preserveAspectRatio",
			"priority",
			"sizes",
			"srcSetCandidates",
		].includes(prop),
});

export const ChakraNextImage = chakra(NextImage, {
	shouldForwardProp: (prop) =>
		["width", "height", "layout", "src", "alt"].includes(prop) ||
		prop.startsWith("aria-"),
});

export const DatoAsset: React.FC<DatoAssetProps> = ({
	data,
	datoImageProps: datoImagePropsExternal,
	usePlaceholder = true,
	svgProps,
	style,
	...props
}) => {
	const datoImageProps: DatoImageProps = datoImagePropsExternal
		? { ...datoImagePropsExternal, usePlaceholder }
		: { usePlaceholder };

	if (data.responsiveImage) {
		return (
			<ChakraDatoImage
				data={data.responsiveImage}
				{...datoImageProps}
				{...props}
				style={style}
			/>
		);
	}

	if (data.svgData) {
		return (
			<chakra.span
				__css={{
					position: "relative",
					svg: {
						...fillParent,
					},
				}}
				/**
				 * We fetched raw SVG from the CDN server side, only editors can
				 * upload SVG so we can assume them to be safe.
				 *
				 * For performance reasons we inline them here:
				 */
				// eslint-disable-next-line react/no-danger
				dangerouslySetInnerHTML={{ __html: data.svgData }}
				{...props}
				{...svgProps}
				style={style}
			/>
		);
	}

	if (data.url && data.width && data.height) {
		// Image is constrained by parent element dimensions, see https://nextjs.org/docs/api-reference/next/image#layout
		return (
			<ChakraNextImage
				width={data.width}
				height={data.height}
				layout="responsive"
				src={data.url}
				{...props}
				alt={props.alt ?? data.alt ?? ""}
				style={style}
			/>
		);
	}

	return null;
};

// 🔬 TBD: Please evaluate
