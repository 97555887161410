// eslint-disable-next-line no-restricted-imports
import { Modal as ChakraModal, ModalProps } from "@chakra-ui/react";

export const Modal: React.FC<ModalProps> = ({ children, ...props }) => (
	<ChakraModal
		size={{ base: "full", md: "2xl", lg: "4xl", xl: "6xl" }}
		variant="funk"
		isCentered={true}
		scrollBehavior="inside"
		// See https://github.com/chakra-ui/chakra-ui/issues/5705
		preserveScrollBarGap={true}
		{...props}
	>
		{children}
	</ChakraModal>
);

// 🔬 TBD: e2e?
