import {
	createElement,
	ReactElement,
	Fragment,
	ReactNode,
	isValidElement,
} from "react";

export type ReplaceStringWithReactNodePairs = Record<string, ReactNode>;

export const replacePipeWithShy = (str?: string) => {
	if (!str) {
		return "";
	}

	return replaceInString(str, "|", "\u00AD");
};

export const replacePipeWithBr = (str?: string) => {
	if (!str) {
		return "";
	}

	return replaceInString(str, "|", createElement("br"));
};

export const replaceInString = (
	haystack: string,
	needle: string,
	replace: ReactNode,
): ReactElement | string => {
	if (!haystack.includes(needle)) {
		return haystack;
	}

	const isElement = isValidElement(replace);
	const parts = haystack
		.split(needle)
		.map((part, index) => {
			const key = [part, index].join("-");

			return [
				part,
				createElement(Fragment, { key }, [
					isElement ? { ...replace, key: "replace-" + key } : replace,
				]),
			];
		})
		.flat()
		.slice(0, -1);

	return createElement(Fragment, { key: haystack }, parts);
};

const replaceInReactNode = (
	haystack: ReactNode,
	needle: string,
	replace: ReactNode,
): ReactNode => {
	if (typeof haystack === "string") {
		return replaceInString(haystack, needle, replace);
	}

	if (Array.isArray(haystack)) {
		return haystack.map(
			// eslint-disable-next-line @typescript-eslint/promise-function-async
			(substack) => replaceInReactNode(substack, needle, replace),
		);
	}

	if (isValidElement<{ children: Array<ReactNode> }>(haystack)) {
		return {
			...haystack,
			props: {
				...haystack.props,
				children: replaceInReactNode(
					haystack.props.children,
					needle,
					replace,
				),
			},
		};
	}

	return haystack;
};

export const replaceStringsWithReactNodes = (
	haystack: ReactNode,
	needles: ReplaceStringWithReactNodePairs,
): ReactNode => {
	let updated: ReactNode = haystack;

	for (const [needle, replace] of Object.entries(needles)) {
		updated = replaceInReactNode(updated, `{${needle}}`, replace);
	}

	return updated;
};

// 🔬 jest unit tested
