import {
	chakra,
	Container,
	Link,
	Text,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import Image from "next/image";
import { useTranslatedString } from "src/i18n/i18n";
import { Instagram, LinkedIn, Youtube } from "src/icons";
import { useSiteSettings, useTargetGroup } from "src/lib/hooks";
import { replaceStringsWithReactNodes } from "src/lib/replaceStringWithReactNode";
import { phoneNumberDisplayToTel } from "src/lib/utils";
import { CMSButton } from "../CMSButton/CMSButton";
import {
	useEditorTools,
	useTenClicksToStartEditorTools,
} from "../EditorTools/EditorTools";
import { Hx } from "../Heading/Heading";
import type { SiteSettings } from "src/lib/ssr/utils";

const buildInfo = JSON.stringify(
	{
		FEX_VERSION: process.env.FEX_VERSION,
		VERCEL_URL_PREDICTED: process.env.VERCEL_URL_PREDICTED,
		VERCEL_ENV: process.env.VERCEL_ENV,
		VERCEL_GIT_COMMIT_REF: process.env.VERCEL_GIT_COMMIT_REF,
	},
	null,
	2,
);

const Footer: React.FC<{
	variant?: string;
	footerSettings: SiteSettings["footerSettings"];
}> = ({ variant, footerSettings }) => {
	const { isEnabled: editorToolsEnabled } = useEditorTools();

	const {
		brandConfig: { slug: brandSlug },
	} = useSiteSettings();

	const targetGroupCtx = useTargetGroup();

	const t = useTranslatedString();
	const styles = useMultiStyleConfig("Footer", { variant });
	const handleEditorToolsClick = useTenClicksToStartEditorTools();

	const phoneNumber =
		targetGroupCtx?.contact.phoneNumber ?? t("footer.defaultPhoneNumber");

	const email = targetGroupCtx?.contact.email ?? t("footer.defaultEmail");

	const footerText = replaceStringsWithReactNodes(footerSettings.text, {
		phoneNumber: (
			<a href={`tel:${phoneNumberDisplayToTel(phoneNumber)}`}>
				{phoneNumber}
			</a>
		),
		email: <a href={`mailto:${email}`}>{email}</a>,
	});

	return (
		<chakra.footer __css={styles.wrapper}>
			<chakra.div __css={styles.socialWrapper}>
				<Container maxW="container.xl" sx={styles.socialInner}>
					<Text paddingTop={4}>{t("footer.socialTitle")}</Text>
					<chakra.div __css={styles.socialChannels}>
						<Link
							href={
								brandSlug.includes("funk-experts")
									? "https://www.linkedin.com/company/funk-experts"
									: "https://de.linkedin.com/company/funk-group/"
							}
							title={t("social.linkedin.title")}
							isExternal
							id="click_linkedin"
						>
							<LinkedIn w="auto" h="32px" />
						</Link>
						{brandSlug.includes("funk-experts") && (
							<Link
								href="https://www.instagram.com/funkexperts"
								title={t("social.instagram.title")}
								isExternal
								id="click_instagram"
							>
								<Instagram w="auto" h="32px" />
							</Link>
						)}
						<Link
							href="https://www.youtube.com/channel/UCGKja91ib9Av6TWxbEHJM4A"
							title={t("social.youtube.title")}
							isExternal
							id="click_youtube"
						>
							<Youtube w="auto" h="32px" fill="currentColor" />
						</Link>
					</chakra.div>
				</Container>
			</chakra.div>

			<Container maxW="container.xl">
				<chakra.div sx={styles.linksWrapper}>
					<chakra.div sx={styles.intro}>
						<Hx as="p" size="h4" sx={styles.introTitle}>
							{footerSettings.title}
						</Hx>
						{footerSettings.text && (
							<chakra.p sx={styles.introText}>
								{footerText}
							</chakra.p>
						)}
					</chakra.div>
					<chakra.div sx={styles.linkColumns}>
						<chakra.ul sx={styles.linkList}>
							{footerSettings.leftColumn?.map((link) => (
								<li key={link.id}>
									<CMSButton {...link} linkStyle="text" />
								</li>
							))}
						</chakra.ul>
						<chakra.ul sx={styles.linkList}>
							{footerSettings.rightColumn?.map((link) => (
								<li key={link.id}>
									<CMSButton {...link} linkStyle="text" />
								</li>
							))}
						</chakra.ul>
					</chakra.div>
				</chakra.div>
			</Container>

			<chakra.div __css={styles.certificatesWrapper}>
				<Container maxW="container.xl" sx={styles.certificatesInner}>
					<chakra.div __css={styles.certificatesLogos}>
						{/* TODO: these could be fetched from the DatoCMS CDN, too */}
						<Image
							src="/img/footer/iso.jpg"
							width={70}
							height={58}
							alt="ISO-zertifiziert"
						/>
						<Image
							src="/img/footer/funk.png"
							width={141}
							height={58}
							alt="Funk Logo"
						/>
					</chakra.div>
					<chakra.div maxWidth="480px">
						<Text>{t("footer.quality")}</Text>
						<Text
							onClick={handleEditorToolsClick}
							fontWeight="normal"
						>
							{t("footer.iso")}
						</Text>
					</chakra.div>
				</Container>
			</chakra.div>
			<chakra.div
				__css={styles.bottomWrapper}
				data-buildinfo={
					editorToolsEnabled ? buildInfo : "editor tools inactive"
				}
			/>
		</chakra.footer>
	);
};

export { Footer };

// 🔬 TBD: Please evaluate
