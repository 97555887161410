import {
	chakra,
	Container,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerHeader,
	useDisclosure,
	UseDisclosureReturn,
	useMultiStyleConfig,
} from "@chakra-ui/react";
import React, { createContext, ReactNode, useContext } from "react";
import { BurgerButton } from "src/components/BurgerButton/BurgerButton";

type MobileMenuProps = {
	logo: ReactNode;
	children: ReactNode;
};

const MobileMenuContext = createContext<
	{ onClose: UseDisclosureReturn["onClose"] } | undefined
>(undefined);

export const useMobileMenuContext = () => useContext(MobileMenuContext);

export const MobileMenu: React.FC<MobileMenuProps> = ({ logo, children }) => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const styles = useMultiStyleConfig("MobileMenu", {
		variant: isOpen ? "open" : undefined,
	});

	return (
		<MobileMenuContext.Provider value={{ onClose }}>
			<chakra.div __css={styles.wrapper}>
				<Container maxW="container.xl" sx={styles.inner}>
					{logo}
					<BurgerButton
						isOpen={isOpen}
						onClick={onToggle}
						id="click_open_mobile_menu"
					/>
				</Container>
				<Drawer
					isOpen={isOpen}
					placement="top"
					onClose={onClose}
					size="full"
					preserveScrollBarGap
					// eslint-disable-next-line jsx-a11y/no-autofocus
					autoFocus={false}
				>
					<DrawerContent
						sx={styles.drawer}
						display={{
							base: "block",
							desktopNavigation: "none",
						}}
					>
						<DrawerHeader sx={styles.drawerHeader}>
							<chakra.div sx={styles.inner}>
								{logo}
								<BurgerButton
									isOpen={true}
									onClick={onToggle}
									id="click_close_mobile_menu"
								/>
							</chakra.div>
						</DrawerHeader>
						<DrawerBody sx={styles.drawerBody}>
							{children}
						</DrawerBody>
					</DrawerContent>
				</Drawer>
			</chakra.div>
		</MobileMenuContext.Provider>
	);
};

// 🔬 TBD: Please evaluate
