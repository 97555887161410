import { Button, chakra, Divider, Link } from "@chakra-ui/react";
import React from "react";
import { NextLink } from "src/components/NextLink";
import { User } from "src/icons";
import { useLogout } from "src/lib/auth";
import { useSiteSettings } from "src/lib/hooks";
import { truthy } from "src/lib/utils";
import { useLoginInfo } from "src/queries/emil/account";
import { buttonHeightMixin } from "src/styles/Button.theme";
import { addDividerToHeaderMenuItems } from "./CMSMenuItems";
import { useMobileMenuContext } from "./MobileMenu/MobileMenu";
import { useTranslatedString } from "../../i18n/i18n";
import { buttonLinkSVGMixin } from "../CMSButton/CMSButton.theme";
import {
	HeaderAccordion,
	HeaderAccordionButton,
	HeaderAccordionContent,
} from "../HeaderAccordion/HeaderAccordion";
import {
	HeaderDropdown,
	HeaderDropdownButton,
	HeaderDropdownContent,
	HeaderDropdownItem,
} from "../HeaderDropdown/HeaderDropdown";
import { openModal } from "../URLModal/modalRoutes";

type AuthHeaderItemProps = {
	href?: string;
	onClick?: () => void;
	label: string;
	id: string;
};
const AuthHeaderItem: React.FC<AuthHeaderItemProps> = ({
	href,
	onClick,
	label,
	...rest
}) => {
	if (href) {
		return (
			<NextLink href={href} passHref>
				<Link onClick={onClick} variant="headerDropdown" {...rest}>
					{label}
				</Link>
			</NextLink>
		);
	}

	return (
		<Button onClick={onClick} {...rest}>
			{label}
		</Button>
	);
};

export const AuthBasedHeader: React.FC = () => {
	const t = useTranslatedString();
	const loginInfo = useLoginInfo();
	const mobileMenuContext = useMobileMenuContext();
	const {
		brandConfig: { hasPortal },
	} = useSiteSettings();

	const logOut = useLogout();

	if (!hasPortal) {
		return null;
	}

	if (loginInfo) {
		const { isCustomer, name, isUser } = loginInfo;

		const items: Array<AuthHeaderItemProps> = [
			isUser && {
				label: t("portal.dashboard"),
				href: "broker-portal",
				onClick: mobileMenuContext?.onClose,
				id: "header_broker_portal_dashboard_link",
			},
			isCustomer && {
				label: t("portal.dashboard"),
				href: "portal",
				onClick: mobileMenuContext?.onClose,
				id: "header_portal_dashboard_link",
			},
			isCustomer && {
				label: t("editUserData"),
				onClick: async () => {
					openModal("UserData");
					mobileMenuContext?.onClose();
				},
				id: "header_user_data_change_button",
			},
			{
				label: t("logout"),
				onClick: () => {
					logOut();
					mobileMenuContext?.onClose();
				},
				id: "header_logout_button",
			},
		].filter(truthy);

		return (
			<>
				<HeaderDropdown>
					<HeaderDropdownButton
						data-testid="auth-header-dropdown-button"
						aria-label={name}
						id="click_logout"
						display={{
							base: "none",
							desktopNavigation: "block",
						}}
						leftIcon={<User />}
						sx={{
							...buttonHeightMixin,

							svg: {
								...buttonLinkSVGMixin,
								marginRight: { base: -2, xl: 2 },
							},
						}}
					>
						<chakra.span display={{ base: "none", xl: "inline" }}>
							{name}
						</chakra.span>
					</HeaderDropdownButton>
					<HeaderDropdownContent>
						{items.map((props) => {
							return (
								<HeaderDropdownItem key={props.id} {...props} />
							);
						})}
					</HeaderDropdownContent>
				</HeaderDropdown>
				<HeaderAccordion
					variant="authHeader"
					display={{
						base: "block",
						desktopNavigation: "none",
					}}
				>
					<HeaderAccordionButton>
						<User
							sx={{
								...buttonLinkSVGMixin,
								marginRight: 3,
							}}
						/>
						{name}
					</HeaderAccordionButton>
					<HeaderAccordionContent mb={4}>
						{items
							.map((props) => {
								return (
									<AuthHeaderItem key={props.id} {...props} />
								);
							})
							.flatMap(
								addDividerToHeaderMenuItems(
									<Divider
										display={{
											base: "block",
											desktopNavigation: "none",
										}}
									/>,
								),
							)}
					</HeaderAccordionContent>
				</HeaderAccordion>
			</>
		);
	}

	return null;
};

// 🔬 TBD: Please evaluate
