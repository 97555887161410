import { useCounter } from "@chakra-ui/react";
import { useLocalStorageValue } from "@react-hookz/web";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import type { EditorToolsUIProps } from "./EditorToolsUI";

export const useEditorTools = () => {
	const { set: setSecret, value: secret } = useLocalStorageValue<
		string | undefined
	>("fex-editor-tools", {
		initializeWithValue: false,
		defaultValue: undefined,
	});

	const isEnabled = Boolean(secret);

	return { setSecret, isEnabled, secret };
};

export const EditorTools: React.FC<{ isPreview: boolean }> = ({
	isPreview,
}) => {
	const { isEnabled, secret } = useEditorTools();

	const EditorToolsUI = isEnabled
		? dynamic<EditorToolsUIProps>(
				async () =>
					import("./EditorToolsUI").then((mod) => mod.EditorToolsUI),
				{ ssr: false },
			)
		: () => null;

	return <EditorToolsUI isPreview={isPreview} secret={secret} />;
};

export const useTenClicksToStartEditorTools = () => {
	const { increment, valueAsNumber } = useCounter({ max: 10 });
	const { setSecret } = useEditorTools();

	useEffect(() => {
		if (valueAsNumber >= 10) {
			// eslint-disable-next-line no-alert
			const secret = prompt();

			if (!secret) {
				return;
			}

			setSecret(secret);
			location.reload();
		}
	}, [setSecret, valueAsNumber]);

	return () => increment();
};

// 🔬 TBD: Please evaluate
