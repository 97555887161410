import { AnyRecord } from "src/types";
import { environments } from "./constants";
import type {
	PolicyVersionClass as CustomerPolicyVersionClass,
	PolicyObjectClass as CustomerPolicyObjectClass,
	Environment,
} from "@emilgroup/customer-sdk";
import type {
	PolicyObjectClass as BrokerPolicyObjectClass,
	PolicyVersionClass as BrokerPolicyVersionClass,
} from "@emilgroup/insurance-sdk";

// we need this because these props mustn't be in the payload when updating a policy
export type ModifiedPolicyObjectClass =
	| Omit<CustomerPolicyObjectClass, "createdAt" | "updatedAt" | "code">
	| Omit<
			BrokerPolicyObjectClass,
			"createdAt" | "updatedAt" | "code" | "summary"
	  >;

export const updatePolicyObjects = (
	input: Array<CustomerPolicyObjectClass | BrokerPolicyObjectClass>,
	update:
		| Record<keyof CustomerPolicyObjectClass["data"], string>
		// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
		| Record<keyof BrokerPolicyObjectClass["data"], string>,
	templateFields: Record<string, string>,
): Array<ModifiedPolicyObjectClass> => {
	const updatedPolicyObjects = structuredClone(input);

	Object.entries(update).forEach(([attribute, value]) => {
		const insuredObjectName = templateFields[attribute];

		const policyObjectIndex = input.findIndex(
			({ insuredObjectName: insuredObjectNameInput }) => {
				return insuredObjectNameInput === insuredObjectName;
			},
		);

		if (policyObjectIndex === -1) {
			throw new Error("Can not find policy object");
		}

		// TODO: borked types in EMIL SDK
		(updatedPolicyObjects[policyObjectIndex]?.data as any)[attribute] =
			value;
	});

	return updatedPolicyObjects.map((po) => {
		if ("summary" in po) {
			const { code, createdAt, updatedAt, summary, ...rest } = po;

			return rest;
		}

		const { code, createdAt, updatedAt, ...rest } = po;

		return rest;
	});
};

export const getAttributeValueFromPolicyObjects = (
	policyObjects: Array<ModifiedPolicyObjectClass>,
	attribute: string,
) => {
	const all = policyObjects.flatMap((f) => f.data as Array<AnyRecord>);
	const relevant = all.find((policyObject) => attribute in policyObject);

	if (!relevant) {
		return undefined;
	}

	return relevant[attribute];
};

export const findCurrentTimelineItem = (
	versions:
		| Array<BrokerPolicyVersionClass>
		| Array<CustomerPolicyVersionClass>,
) => {
	const currentVersion = versions[0];

	if (!currentVersion) {
		return undefined;
	}

	// id property is only present in the BrokerPolicyVersionClass. Type narrowing is requested even though the code is the same
	const toIsNull =
		"id" in currentVersion
			? currentVersion.timeline.find((i) => i.to === null)
			: // TODO: borked types in EMIL SDK
				// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
				currentVersion.timeline.find((i) => i.to === null);

	const lastTimelineItem =
		currentVersion.timeline[currentVersion.timeline.length - 1];

	return toIsNull ?? lastTimelineItem;
};

export const findCurrentPolicyObjects = (
	versions:
		| Array<CustomerPolicyVersionClass>
		| Array<BrokerPolicyVersionClass>,
) => findCurrentTimelineItem(versions)?.policyObjects;

export const getEmilEnv = () =>
	(environments as any)[process.env.EMIL_ENV ?? "prod"] as Environment;

// 🔬 TBD: Please evaluate
