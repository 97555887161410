import { ReactNode } from "react";
import { createContext } from "../../lib/react";
import type { UseDisclosureReturn } from "@chakra-ui/react";

type HeaderDropdownContextType = {
	isOpen: UseDisclosureReturn["isOpen"];
	onClose: UseDisclosureReturn["onClose"];
	disclosureOnClose: UseDisclosureReturn["onClose"];
	onOpen: UseDisclosureReturn["onOpen"];
	onMouseEnter: () => void;
};

const [HeaderDropdownContext, useHeaderDropdownContext] =
	createContext<HeaderDropdownContextType>("HeaderDropdown");

export { useHeaderDropdownContext };

export const HeaderDropdownContextProvider: React.FC<{
	value: HeaderDropdownContextType;
	children: ReactNode;
}> = ({ value, children }) => (
	<HeaderDropdownContext.Provider value={value}>
		{children}
	</HeaderDropdownContext.Provider>
);

// 🔬 TBD: Please evaluate
