import { useStyleConfig } from "@chakra-ui/react";
import { useMemo } from "react";
import { InlineModalButtonFragment } from "src/__generated__/cms-schema.codegen";
import { useProductSlug, useTargetGroup } from "src/lib/hooks";
import { CMSButtonLabel } from "./CMSButtonLabel";
import { PremiumCalcButton } from "../PremiumCalcModal/PremiumCalcButton";
import { URLModalButton } from "../URLModal/URLModalButton";
import { ModalRoutes } from "../URLModal/modalRoutes";

export type CMSModalButtonProps = InlineModalButtonFragment & {
	inHeader?: boolean;
};

export const CMSModalButton: React.FC<CMSModalButtonProps> = ({
	modalName,
	analyticsId,
	linkStyle,
	leftIcon,
	rightIcon,
	label,
	enablePrefill,
	inHeader,
	product,
}) => {
	const sx = useStyleConfig("CMSButton", {
		variant: linkStyle,
		inHeader,
	});

	const common = {
		variant: linkStyle,
		sx,
		"data-testid": "cms-modal-button",
	};

	const targetGroupCtx = useTargetGroup();

	const targetGroup = useMemo(() => {
		const tg = product?.targetGroup
			? {
					id: product.targetGroup.id,
					slug: product.targetGroup.slug,
				}
			: targetGroupCtx;

		return tg?.slug === "experts" ? undefined : tg;
	}, [product?.targetGroup, targetGroupCtx]);

	const productSlugCtx = useProductSlug();

	const insuranceProductSlug = product
		? product.slug
		: productSlugCtx ?? undefined;

	if (modalName === "PremiumCalc") {
		// targetGroup information may be retrieved inside PremiumCalcButton via context
		return (
			<PremiumCalcButton
				id={analyticsId}
				insuranceProductSlug={insuranceProductSlug}
				targetGroupSlug={targetGroup?.slug}
				enablePrefill={enablePrefill ?? false}
				{...common}
			>
				<CMSButtonLabel
					leftIcon={leftIcon?.name}
					rightIcon={rightIcon?.name}
					label={label}
				/>
			</PremiumCalcButton>
		);
	}

	return (
		<URLModalButton
			id={analyticsId}
			modalName={modalName as keyof ModalRoutes}
			{...common}
		>
			<CMSButtonLabel
				leftIcon={leftIcon?.name}
				rightIcon={rightIcon?.name}
				label={label}
			/>
		</URLModalButton>
	);
};

// 🔬 RTL
