import {
	// eslint-disable-next-line no-restricted-imports
	useToast as useOriginalToast,
	UseToastOptions,
} from "@chakra-ui/react";
import { useTranslatedString } from "src/i18n/i18n";

const useToast = () => {
	const toast = useOriginalToast();

	return (...args: Parameters<typeof toast>) => {
		// If an id is set on the options, we prevent duplicate toasts
		if (!args[0]?.id || !toast.isActive(args[0].id)) {
			toast(...args);
		}
	};
};

const isErrorObject = (error: unknown): error is { message: string } => {
	return (
		Boolean(error) &&
		error !== null &&
		typeof error === "object" &&
		"message" in error
	);
};

export const useGenericErrorToast = () => {
	const toast = useToast();
	const t = useTranslatedString();

	return (error: unknown, options?: UseToastOptions) => {
		const errorMessage =
			typeof error === "string"
				? error
				: isErrorObject(error)
					? error.message
					: error?.toString() ?? "Unknown error";

		return toast({
			status: "error",
			isClosable: false,
			duration: 8000,
			description: t("toast.genericError", {
				error: errorMessage,
			}),
			...options,
		});
	};
};

export const useErrorToast = () => {
	const toast = useToast();

	return (description: string, options?: UseToastOptions) => {
		return toast({
			status: "error",
			isClosable: false,
			duration: 8000,
			description,
			...options,
		});
	};
};

export const useInfoToast = () => {
	const toast = useToast();

	return (options?: UseToastOptions) =>
		toast({
			status: "info",
			isClosable: true,
			duration: 8000,
			...options,
		});
};

// 🔬 RTL
