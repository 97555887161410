import { useLocalStorageValue } from "@react-hookz/web";
import { Documents } from "./Notification";

export const useLogoutDateFromLocalStorage = () => {
	const { value: logoutDate, set: setLogoutDate } = useLocalStorageValue<{
		logoutDate: string;
		fetched: boolean;
	}>("fex-logout-date", {
		initializeWithValue: false,
		defaultValue: undefined,
	});

	return { logoutDate, setLogoutDate };
};

export const useDocumentsFromLocalStorage = () => {
	const { set: setDocuments, value: documents } =
		useLocalStorageValue<Documents>("fex-documents", {
			initializeWithValue: true,
			defaultValue: [],
		});

	return { documents, setDocuments };
};

// 🔬 TBD
