import React from "react";
import {
	CMSLinkButton,
	CMSLinkButtonProps,
} from "src/components/CMSButton/CMSLinkButton";
import {
	CMSModalButton,
	CMSModalButtonProps,
} from "src/components/CMSButton/CMSModalButton";

type CMSButtonProps = CMSLinkButtonProps | CMSModalButtonProps;

export const CMSButton: React.FC<CMSButtonProps> = (props) => {
	return props.__typename === "InlineLinkButtonRecord" ? (
		<CMSLinkButton {...props} />
	) : (
		<CMSModalButton {...props} />
	);
};

// 🔬 RTL (via the two components rendered here)
