import { useMultiStyleConfig } from "@chakra-ui/react";
import React, { ReactElement, ReactEventHandler } from "react";

type CmsMenuItemProps = {
	children: ReactElement;
	onClick?: () => void;
};

export const CmsMenuItem: React.FC<CmsMenuItemProps> = ({
	children,
	onClick,
}) => {
	const styles = useMultiStyleConfig("CmsMenuItem");

	return (
		<>
			{React.Children.map(children, (child) =>
				React.cloneElement(child, {
					onClick: (ev: ReactEventHandler["arguments"]) => {
						child.props.onClick?.(ev);
						onClick?.();
					},
					__css: { ...child.props.__css, ...styles.wrapper },
				}),
			)}
		</>
	);
};

// 🔬 TBD: Please evaluate
