import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { encodeUrlParams } from "react-url-modal";
import { useLogoutDateFromLocalStorage } from "src/components/Notification/hooks";
import { useEmilContext } from "./emil/context";

export const goToLoginPage = async (loggedOut = true) => {
	const params = new URLSearchParams({
		modal: "Login",
		params: encodeUrlParams({ loggedOut }),
	}).toString();

	window.location.replace(`/?${params}`);
};

export const goToHome = () => {
	window.location.replace(`/`);
};

export const useLogout = () => {
	const queryClient = useQueryClient();
	const { reset, authApi } = useEmilContext();

	const { setLogoutDate } = useLogoutDateFromLocalStorage();

	return useCallback(() => {
		setLogoutDate({
			logoutDate: new Date().toISOString(),
			fetched: false,
		});

		authApi.cleanTokenData();
		reset();
		queryClient.clear();
		goToLoginPage();
	}, [authApi, queryClient, reset, setLogoutDate]);
};

// 🔬 TBD: Please evaluate
