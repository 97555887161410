import { UrlObject } from "url";
import Original from "next/link";
import { ReactNode } from "react";
import type { LinkProps } from "next/link";

type NextLinkProps = LinkProps & { children: ReactNode };

const addLeadingSlashToString = (path: string) =>
	path.startsWith("/") ? path : `/${path}`;

const addLeadingSlashToUrlObject = (url: UrlObject) =>
	url.pathname && !url.pathname.startsWith("/")
		? { ...url, pathname: `/${url.pathname}` }
		: url;

export const NextLink: React.FC<NextLinkProps> = (props) => {
	const link = props.href;
	const href =
		typeof link === "string"
			? addLeadingSlashToString(link)
			: addLeadingSlashToUrlObject(link);

	return <Original {...props} href={href} legacyBehavior />;
};

// 🔬 skip for now
