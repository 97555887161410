import {
	Link,
	ChakraProps,
	useMultiStyleConfig,
	chakra,
} from "@chakra-ui/react";
import { NextLink } from "src/components/NextLink";
import { HeaderProps } from "./Header";
import { useTranslatedString } from "../../i18n/i18n";
import { LogoFallback } from "../../icons";
import { DatoAsset } from "../Dato/DatoAsset";

export type HeaderLogoProps = ChakraProps & {
	analyticsId: string;
	variant?: HeaderProps["variant"];
	logoData?: HeaderProps["logoData"];
};

export const HeaderLogo: React.FC<HeaderLogoProps> = ({
	analyticsId,
	logoData,
	variant,
	...rest
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("Header", { variant });

	const sharedAttr = {
		sx: styles.logo,
		"data-axe-ignore": true,
		preserveAspectRatio: "xMinYMin meet",
	};

	return (
		<NextLink href="/#top" passHref>
			<Link title={t("jumpToTop")} id={analyticsId}>
				<chakra.div __css={styles.logoWrapper} {...rest}>
					{logoData ? (
						<DatoAsset data={logoData} {...sharedAttr} />
					) : (
						<LogoFallback {...sharedAttr} />
					)}
				</chakra.div>
			</Link>
		</NextLink>
	);
};

// 🔬 TBD: Please evaluate
