import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
} from "@chakra-ui/react";
import { useTranslatedString } from "src/i18n/i18n";

export const NoScript = () => {
	const t = useTranslatedString();

	return (
		<noscript>
			<Alert zIndex="overlay" status="error">
				<AlertIcon />
				<AlertTitle>{t("noscript.title")}</AlertTitle>
				<AlertDescription>{t("noscript.text")}</AlertDescription>
			</Alert>
		</noscript>
	);
};

// 🔬 skip
