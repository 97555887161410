import dynamic from "next/dynamic";
import React, { ReactNode } from "react";

type NoSsrProps = {
	children: ReactNode;
};

const NoSSRComponent: React.FC<NoSsrProps> = ({ children }) => {
	return <>{children}</>;
};

export const NoSSR = dynamic(async () => Promise.resolve(NoSSRComponent), {
	ssr: false,
});

// 🔬 TBD: Please evaluate
