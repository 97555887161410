import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { openModal } from "react-url-modal";
import { modalRoutes, ModalRouteProps, ModalRoutes } from "./modalRoutes";

export type URLModalButtonProps<
	ModalName extends keyof ModalRoutes = keyof ModalRoutes,
> = ButtonProps & {
	modalName: ModalName;
	params?: ModalRouteProps[ModalName];
};

export const URLModalButton = <ModalName extends keyof ModalRoutes>({
	modalName,
	params,
	children,
	...rest
}: URLModalButtonProps<ModalName>) => {
	return (
		<Button
			onClick={async () => openModal({ name: modalName, params })}
			onMouseEnter={async () => {
				try {
					// We are using an undocumented API from next/router here to preload the modal once the user hovers the button. This should avoid loading flickering. We wrap the method call in try-catch to account for future breaking changes to the API. See https://github.com/vercel/next.js/discussions/13738#discussioncomment-332105 for details.
					(modalRoutes[modalName] as unknown as any).render.preload();
				} catch (err) {
					// eslint-disable-next-line no-console
					console.error(
						"Uncaught Error: Could not preload Modal: ",
						modalName,
					);
				}
			}}
			{...rest}
		>
			{children}
		</Button>
	);
};

// 🔬 TBD: Please evaluate
