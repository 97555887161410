import { useLocalStorageValue } from "@react-hookz/web";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslatedString } from "src/i18n/i18n";
import {
	useAccountsApi,
	useCustomersApi,
	useInvitesApi,
} from "src/lib/emil/context";
import { isAuthError } from "src/lib/emil/utils";
import { usePortalType } from "src/lib/hooks";
import { useInfoToast } from "src/lib/toasts";
import { z } from "zod";
import type { AccountClass as AccountClassExternal } from "@emilgroup/account-sdk";
import type {
	AccountClass,
	UpdateAccountRequestDto,
} from "@emilgroup/customer-sdk";

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
const decodeJWT = (token: string): unknown => {
	const base64Url = token.split(".")[1];
	const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");

	if (!base64) {
		return undefined;
	}

	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map((c) => {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join(""),
	);

	return JSON.parse(jsonPayload);
};

const useJwt = () => {
	const { value } = useLocalStorageValue("APP_TOKEN", {
		initializeWithValue: false,
	});

	if (!value) {
		return { decoded: undefined, token: undefined };
	}

	try {
		const token = z
			.object({
				accessToken: z
					.string()
					.refine((s) => s.split(".").length === 3),
			})
			.parse(value).accessToken;

		const decoded = decodeJWT(token);

		return { decoded, token };
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);

		return { decoded: undefined, token: undefined };
	}
};

export const useLoginInfo = () => {
	const { decoded } = useJwt();

	return useMemo(() => {
		if (!decoded) {
			return undefined;
		}

		try {
			const data = z
				.object({
					given_name: z.string(),
					family_name: z.string(),
					sub: z.string(),
					email: z.string().email(),
					"custom:role": z.string(),
					"custom:tenant_id": z.string(),
					"custom:tenant_slug": z.string(),
					"custom:tenant_hierarchy": z.string(),
				})
				.parse(decoded);

			const tenantHierarchy = data["custom:tenant_hierarchy"];
			const tenantHierarchyLength = tenantHierarchy.split(":").length;

			const isCustomer = data["custom:role"] === "customer";

			// This may lead to false positives if future hierarchies are introduced
			const isBroker =
				data["custom:role"] === "user" && tenantHierarchyLength < 4;

			const isPropertyManager =
				data["custom:role"] === "user" && tenantHierarchyLength >= 4;

			return {
				name: `${data.given_name} ${data.family_name}`,
				email: data.email,
				tenantId: parseInt(data["custom:tenant_id"], 10),
				tenantSlug: data["custom:tenant_slug"],
				sub: data.sub,
				isBroker,
				isPropertyManager,
				isUser: isBroker || isPropertyManager,
				isCustomer,
				tenantHierarchy,
			};
		} catch (_) {
			return undefined;
		}
	}, [decoded]);
};

export const useHasLoginToken = (): boolean => {
	const info = useLoginInfo();

	return Boolean(info?.email);
};

export const useGetAccountInfo = (code?: string) => {
	const accountsApi = useAccountsApi();
	const customersApi = useCustomersApi();
	const portal = usePortalType();

	return useQuery({
		queryKey: [portal, code],
		queryFn: async () => {
			if (!code) {
				return null;
			}

			const { data } =
				portal === "broker-portal"
					? await accountsApi.getAccount({ code })
					: await customersApi.getCustomer({
							customerCode: "me",
							expand: "account",
						});

			return "account" in data ? data.account : data.customer.account;
		},
	});
};

export const useCustomerAccountInfo = () => {
	const loginInfo = useLoginInfo();
	const infoToast = useInfoToast();
	const t = useTranslatedString();
	const hasLoginToken = Boolean(loginInfo?.email);

	const api = useCustomersApi();

	const queryFn = async () => {
		const baseInfo = { ...loginInfo, account: null };

		if (!hasLoginToken || !loginInfo?.isCustomer) {
			return baseInfo;
		}

		try {
			const details = await api.getCustomer({
				customerCode: "me",
				expand: "account",
			});

			return {
				...baseInfo,
				account: details.data.customer.account,
			};
		} catch (error) {
			if (isAuthError(error)) {
				api.cleanTokenData();
				infoToast({
					description: t("toast.authTimeout"),
					id: "logged-out",
				});

				return baseInfo;
			}

			throw error;
		}
	};

	return useQuery({
		queryKey: ["accountInfo", hasLoginToken, loginInfo],
		enabled: hasLoginToken,
		queryFn,
	});
};

export const useVerifyInviteToken = (inviteToken: string | null) => {
	const invitesApi = useInvitesApi();

	return useQuery({
		queryKey: ["token", "verify", inviteToken],
		queryFn: async () => {
			if (inviteToken === null) {
				throw new Error("Token is required");
			}

			return invitesApi.verifyInvite({
				inviteToken,
			});
		},
		enabled: typeof inviteToken === "string",
		retry: false,
	});
};

export const useUpdateCustomerDetails = () => {
	const api = useCustomersApi();
	const { data: accountInfo } = useCustomerAccountInfo();
	const client = useQueryClient();

	return useMutation({
		mutationFn: async (userData: UpdateAccountRequestDto) => {
			const account = {
				...userData,
				type: accountInfo?.account?.type,
			};

			await api.updateCustomer({
				customerCode: "me",
				updateCustomerRequestDto: {
					customerCode: "me",
					account,
				},
			});

			return client.invalidateQueries({ queryKey: ["accountInfo"] });
		},
	});
};

export const isPersonalUser = (
	account?: AccountClass | AccountClassExternal | null | undefined,
) => account?.type === "person";

export const isOrgUser = (
	account?: AccountClass | AccountClassExternal | null | undefined,
) => account?.type === "org";

// 🔬 TBD: Please evaluate
