import {
	Button,
	ButtonProps,
	chakra,
	HTMLChakraProps,
	Menu,
	MenuButton,
	MenuItem,
	MenuItemProps,
	MenuList,
	MenuListProps,
	MenuProps,
	Portal,
	useDisclosure,
	useMultiStyleConfig,
	useOutsideClick,
	usePopper,
} from "@chakra-ui/react";
import debounce from "lodash/debounce";
import React, { ReactNode, useCallback, useRef } from "react";
import { NextLink } from "src/components/NextLink";
import {
	HeaderDropdownContextProvider,
	useHeaderDropdownContext,
} from "./context";
import type { LinkProps } from "next/link";

type HeaderDropdownItemProps = {
	href?: LinkProps["href"];
	isExternal?: boolean;
	label: ReactNode;
	variant?: string;
} & MenuItemProps;

export const HeaderDropdownItem: React.FC<HeaderDropdownItemProps> = ({
	href,
	onClick,
	label,
	icon,
	isExternal = false,
	...rest
}) => {
	const styles = useMultiStyleConfig("HeaderDropdown", {
		variant: icon ? "withArrow" : undefined,
	});
	const { disclosureOnClose: onClose } = useHeaderDropdownContext();

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement>) => {
			onClose();
			onClick?.(e);
		},
		[onClick, onClose],
	);

	// In AuthHeader for example, some menuItems call openModal via their onClick
	if (!href) {
		return (
			<MenuItem
				icon={icon}
				onClick={handleClick}
				sx={styles.menuItem}
				{...rest}
			>
				{label}
			</MenuItem>
		);
	}

	if (isExternal) {
		return (
			<MenuItem
				as="a"
				icon={icon}
				onClick={handleClick}
				sx={styles.menuItem}
				href={href}
				{...rest}
			>
				{label}
			</MenuItem>
		);
	}

	return (
		<NextLink href={`/${href}`} passHref>
			<MenuItem
				as="a"
				icon={icon}
				onClick={handleClick}
				sx={styles.menuItem}
				{...rest}
			>
				{label}
			</MenuItem>
		</NextLink>
	);
};

export const HeaderDropdownButton: React.FC<ButtonProps> = ({
	children,
	...rest
}) => {
	const ctx = useHeaderDropdownContext();
	const styles = useMultiStyleConfig("HeaderDropdown", {
		isOpen: ctx.isOpen,
	});

	return (
		<MenuButton
			data-testid="header-dropdown-button"
			as={Button}
			onClick={ctx.onOpen}
			onKeyDown={(event) => {
				if (event.key === "Enter") {
					ctx.onOpen();
				}
			}}
			onMouseEnter={ctx.onMouseEnter}
			onMouseLeave={ctx.onClose}
			sx={styles.button}
			{...rest}
		>
			{children}
		</MenuButton>
	);
};

export const MenuArrow: React.FC<HTMLChakraProps<"div">> = (props) => {
	const { getArrowProps, getArrowInnerProps } = usePopper();

	return (
		<chakra.div
			{...getArrowProps({
				shadowColor: "lightgray",
				size: "1rem",
				bg: "white",
				zIndex: "1 !important",
			})}
			{...props}
		>
			<chakra.div {...getArrowInnerProps({})} />
		</chakra.div>
	);
};

export const HeaderDropdownContent: React.FC<MenuListProps> = ({
	children,
	...rest
}) => {
	const styles = useMultiStyleConfig("HeaderDropdown");
	const menuListRef = useRef<HTMLDivElement>(null);

	const ctx = useHeaderDropdownContext();

	// NOTE: if, for some reason, onMouseLeave doesn't trigger we can still close the menu by clicking outside of the list
	useOutsideClick({ ref: menuListRef, handler: ctx.onClose });

	return (
		<Portal>
			<MenuList
				ref={menuListRef}
				sx={styles.menuList}
				zIndex="dropdown"
				onKeyDown={(event) => {
					// NOTE: we need to listen to the escape key, because we are using a controlled Menu
					if (event.key === "Escape") {
						ctx.disclosureOnClose();
					}
				}}
				onMouseEnter={ctx.onMouseEnter}
				onMouseLeave={ctx.onClose}
				{...rest}
			>
				{children}
			</MenuList>
		</Portal>
	);
};

export const HeaderDropdown: React.FC<
	MenuProps & { openOnHover?: boolean }
> = ({ children, openOnHover = true, ...rest }) => {
	const { onOpen, onClose: disclosureOnClose, isOpen } = useDisclosure();

	const onClose = debounce(disclosureOnClose, 1500);

	const onMouseEnter = useCallback(() => {
		if (openOnHover) {
			onClose.cancel();
			onOpen();
		}
	}, [onClose, onOpen, openOnHover]);

	return (
		<HeaderDropdownContextProvider
			value={{ isOpen, onClose, onOpen, onMouseEnter, disclosureOnClose }}
		>
			<Menu isOpen={isOpen} {...rest}>
				{children}
			</Menu>
		</HeaderDropdownContextProvider>
	);
};

// 🔬 TBD: Please evaluate
